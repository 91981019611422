import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesWish: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Wishlist tips</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_wishlist.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Wishlist</h1>
          <h2>Best characters to put on your Wishlist in AFK Journey.</h2>
          <p>
            Last updated: <strong>18/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Wishlist" />
        <p>
          The wishlist{' '}
          <strong>
            unlocks after doing 30 pulls when it comes to the All-Hero
            Recruitment banner
          </strong>{' '}
          and is available from the start for the Epic Recruitment banner.{' '}
          <strong>
            When you fully fill the wishlist, any character you pull will come
            from the Wishlist
          </strong>
          . This will help you a lot in narrowing down the heroes you want to
          obtain and smoother your progression. That's why properly setting up
          the tier list with strong heroes is very important.
        </p>
        <ul>
          <li>
            For the All-Hero Recruitment banner Wishlist, you can select 2x{' '}
            <AFKItem name="A-Level" /> and 2x <AFKItem name="S-Level" />{' '}
            characters per faction.{' '}
          </li>
          <li>
            For the Epic Recruitment Banner the Wishlist has only 5 spots, so in
            most cases you will only fill it with <AFKItem name="S-Level" />{' '}
            characters.
          </li>
          <ul>
            <li>
              Keep in mind that you can select <AFKItem name="A-Level" /> units
              on this Wishlist too, but you will always receive an Epic version
              of them if you pull them (this means that you get 4 soulstones of
              theirs if you already own the unit, a quick way to upgrade them to
              higher ranks).
            </li>
          </ul>
        </ul>
        <p>
          When it comes to <AFKItem name="S-Level" /> characters, ascending them
          is very important and requires both dupes and Acorns. So making a
          mistake in focusing your resources and 'wishlist' spot into someone
          who doesn't deserve it will kick it in your ass soon, slowing down
          your progression. As for <AFKItem name="A-Level" /> characters, you
          require a lot more dupes (64 compared to 8 for S-Level) to raise an
          A-Level character to Supreme+, so again you have to wisely choose who
          you want to place on your wishlist.
        </p>
        <SectionHeader title="Wishlist Setup" />
        <h6>One copy wonders</h6>
        <p>
          Below you will find characters that you want to get 1 copy of before
          starting to invest into others. It doesn't mean that the characters
          below will last you forever with one copy, but rather that they work
          really well early without dupes - eventually though you will want to
          raise them too.
        </p>
        <ul className="bigger-margin">
          <li>
            <AFKCharacter mode="inline" slug="rowan" enablePopover /> - used in{' '}
            <strong className="role afk">AFK Stages</strong> and{' '}
            <strong className="role pvp">PVP</strong>,
          </li>
          <li>
            <AFKCharacter mode="inline" slug="brutus" enablePopover /> - used in{' '}
            <strong className="role afk">AFK Stages</strong>,
          </li>
          <li>
            <AFKCharacter mode="inline" slug="ludovic" enablePopover /> - used
            in <strong className="role afk">AFK Stages</strong>,{' '}
            <strong className="role dream">Dream Realm</strong> and{' '}
            <strong className="role pvp">PVP</strong>,
          </li>
          <li>
            <AFKCharacter mode="inline" slug="kruger" enablePopover /> - used in{' '}
            <strong className="role dream">Dream Realm</strong>.
          </li>
        </ul>
        <h6>Wishlist setup - All-Hero Recruitment</h6>
        <p>
          The characters are listed <strong>in the order of priority</strong> -
          so you should place number 1 and 2 on the wishlist first and once you
          get them to <strong className="supreme">Supreme+</strong> rank, you
          move to the next one.
        </p>
        <div className="afk-factions-table wishlist">
          <div className="faction-div Graveborn">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_grave.png"
                alt="Graveborn"
                className="faction-icon"
              />{' '}
              Graveborn
            </h5>
            <div className="info">
              <AFKItem name="S-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="thoran" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>,{' '}
                  <strong className="role dream">Dream Realm</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="carolina" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="ludovic" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>,{' '}
                  <strong className="role dream">Dream Realm</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cecia" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>.
                </li>
              </ol>
              <AFKItem name="A-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="silvina" enablePopover /> -
                  used in <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="viperian" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="niru" enablePopover /> -
                  used in <strong className="role pvp">PVP</strong>.
                </li>
              </ol>
            </div>
          </div>
          <div className="faction-div Mauler">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_mauler.png"
                alt="Mauler"
                className="faction-icon"
              />{' '}
              Maulers
            </h5>
            <div className="info">
              <AFKItem name="S-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter
                    mode="inline"
                    slug="smokey-and-meerky"
                    enablePopover
                  />{' '}
                  - used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role dream">Dream Realm</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>,{' '}
                  <strong className="role dream">Dream Realm</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="shakir" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>,{' '}
                  <strong className="role dream">Dream Realm</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="alsa" enablePopover /> -
                  used in <strong className="role dream">Dream Realm</strong>{' '}
                  and <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="brutus" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>.
                </li>
              </ol>
              <AFKItem name="A-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="odie" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>,{' '}
                  <strong className="role dream">Dream Realm</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="koko" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>,{' '}
                  <strong className="role dream">Dream Realm</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="kruger" enablePopover /> -
                  used in <strong className="role dream">Dream Realm</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="antandra" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>.
                </li>
              </ol>
            </div>
          </div>
          <div className="faction-div Wilder">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_wilder.png"
                alt="Wilder"
                className="faction-icon"
              />{' '}
              Wilders
            </h5>
            <div className="info">
              <AFKItem name="S-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="lily-may" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong>,{' '}
                  <strong className="role dream">Dream Realm</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="eironn" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="hewynn" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="florabelle" enablePopover />{' '}
                  - used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>.
                </li>
              </ol>
              <AFKItem name="A-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="arden" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="damian" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="ulmus" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="parisa" enablePopover /> -
                  used in <strong className="role pvp">PVP</strong>.
                </li>
              </ol>
            </div>
          </div>
          <div className="faction-div Lightbearer">
            <h5>
              <StaticImage
                src="../../../images/afk/icons/faction_light.png"
                alt="Lightbearers"
                className="faction-icon"
              />{' '}
              Lightbearers
            </h5>
            <div className="info">
              <AFKItem name="S-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="vala" enablePopover /> -
                  used in <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="temesia" enablePopover /> -
                  used in <strong className="role dream">Dream Realm</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="rowan" enablePopover /> -
                  used in <strong className="role afk">AFK Stages</strong> and{' '}
                  <strong className="role pvp">PVP</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="cassadee" enablePopover /> -
                  used in <strong className="role dream">Dream Realm</strong>.
                </li>
              </ol>
              <AFKItem name="A-Level" />
              <ol className="bigger-margin">
                <li>
                  <AFKCharacter mode="inline" slug="marilee" enablePopover /> -
                  used in <strong className="role dream">Dream Realm</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="korin" enablePopover /> -
                  used in <strong className="role dream">Dream Realm</strong>,
                </li>
                <li>
                  <AFKCharacter mode="inline" slug="lucius" enablePopover /> -
                  used in most content when he's needed.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <h6>Wishlist setup - Epic Recruitment</h6>
        <p>
          Here are the five characters you{' '}
          <strong>should place on your Epic Wishlist</strong> as obtaining their
          dupes is the most important as you progress through the game.
        </p>
        <div className="employees-container afk-cards">
          <AFKCharacter
            mode="card"
            slug="lily-may"
            showLabel
            showTags
            showIcon
            enablePopover
          />
          <AFKCharacter
            mode="card"
            slug="eironn"
            showLabel
            showTags
            showIcon
            enablePopover
          />
          <AFKCharacter
            mode="card"
            slug="thoran"
            showLabel
            showTags
            showIcon
            enablePopover
          />
          <AFKCharacter
            mode="card"
            slug="smokey-and-meerky"
            showLabel
            showTags
            showIcon
            enablePopover
          />
          <AFKCharacter
            mode="card"
            slug="mikola"
            showLabel
            showTags
            showIcon
            enablePopover
          />
        </div>
        <SectionHeader title="Cele/Hypo priority" />
        <p>
          Here is the order you should invest into Cele/Hypo characters and
          where they are used (Talene and Scarlita are tied for #3 - who you
          pick is up to you).
        </p>
        <ul className="bigger-margin hide-bullets">
          <li>
            <strong className="number">#1</strong>{' '}
            <AFKCharacter mode="inline" slug="reinier" enablePopover /> - used
            in <strong className="role afk">AFK Stages</strong>,{' '}
            <strong className="role dream">Dream Realm</strong> and{' '}
            <strong className="role pvp">PVP</strong>. Get him to{' '}
            <strong className="mythic">Mythic+</strong> as that's enough and
            move to the next character,
          </li>
          <li>
            <strong className="number">#2</strong>{' '}
            <AFKCharacter mode="inline" slug="phraesto" enablePopover /> - used
            in <strong className="role afk">AFK Stages</strong>,{' '}
            <strong className="role dream">Dream Realm</strong> and{' '}
            <strong className="role pvp">PVP</strong>. Get him to{' '}
            <strong className="supreme">Supreme+</strong> (but he already works
            decently well at <strong className="mythic">Mythic+</strong>),
          </li>
          <li>
            <strong className="number">#3</strong>{' '}
            <AFKCharacter mode="inline" slug="scarlita" enablePopover /> - used
            in <strong className="role afk">AFK Stages</strong>,{' '}
            <strong className="role dream">Dream Realm</strong> and{' '}
            <strong className="role pvp">PVP</strong>. Get her to{' '}
            <strong className="supreme">Supreme+</strong>,
          </li>
          <li>
            <strong className="number">#-</strong>{' '}
            <AFKCharacter mode="inline" slug="talene" enablePopover /> - used in{' '}
            <strong className="role afk">AFK Stages</strong>,{' '}
            <strong className="role dream">Dream Realm</strong> and{' '}
            <strong className="role pvp">PVP</strong>. Get her to{' '}
            <strong className="supreme">Supreme+</strong>,
          </li>
          <li>
            <strong className="number">#5</strong>{' '}
            <AFKCharacter mode="inline" slug="dionel" enablePopover /> - used in{' '}
            <strong className="role pvp">PVP</strong>. Get him to{' '}
            <strong className="supreme">Supreme+</strong>,
          </li>
          <li>
            <strong className="number">#6</strong>{' '}
            <AFKCharacter mode="inline" slug="dunlingr" enablePopover /> - used
            in <strong className="role pvp">PVP</strong>. Get him to{' '}
            <strong className="supreme">Supreme+</strong> (but he already works
            decently well at <strong className="mythic">Mythic+</strong> and
            even at 1 copy - depending on your rank in the Arena),
          </li>
          <li>
            <strong className="number">#7</strong>{' '}
            <AFKCharacter mode="inline" slug="berial" enablePopover /> - used in{' '}
            <strong className="role pvp">PVP</strong>. Get him to{' '}
            <strong className="supreme">Supreme+</strong>.
          </li>
        </ul>
        <SectionHeader title="Videos" />
        <p>For additional information about the Cele/Hypo, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="60Jja-gR0aQ" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesWish;

export const Head: React.FC = () => (
  <Seo
    title="Wishlist | AFK Journey | Prydwen Institute"
    description="Best characters to put on your Wishlist in AFK Journey."
    game="afk"
  />
);
